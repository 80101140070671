import React from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import Link from '../../components/Link';

const PrivacyEn = ({ gender, isMobileView }) => {
  const genderPath = gender === 'women' ? '/femme' : '';
  return (
    <div>
      {isMobileView ? (
        <PageTitle shadow={gender === 'men' ? true : false}>
          {gender === 'men' ? (
            <p>
              歐萊雅香港有限公司
              <br />
              私隱聲明
            </p>
          ) : (
            '私隱政策及個人資料收集聲明'
          )}
        </PageTitle>
      ) : (
        <h1>
          {gender === 'men' ? (
            <p>
              歐萊雅香港有限公司
              <br />
              私隱聲明
            </p>
          ) : (
            '私隱政策及個人資料收集聲明'
          )}
        </h1>
      )}
      {gender === 'men' ? (
        <div className="bootstrap page-privacy">
          <strong>
            歐萊雅的目標是要成為一個模範企業。我們待客坦誠，理念清晰。我們在信任及互利的基礎上，致力與客戶建立鞏固而持久關係。憑著這一理念，保障你的私隱成為我們重要的使命。因此，我們鼓勵你閱讀本私隱聲明，讓你明瞭我們是如何收集及處理你的個人資料。
            <br />
            <br />
            歐萊雅香港有限公司的品牌:
          </strong>
          <br />
          <br />
          <table class="brand">
            <tbody>
              <tr>
                <td>Biotherm</td>
                <td>Clarisonic</td>
                <td>Diesel</td>
                <td>Essie</td>
                <td>Garnier</td>
                <td>Giorgio Armani</td>
              </tr>
              <tr>
                <td>Helena Rubinstein</td>
                <td>Kérastase</td>
                <td>Kiehl's</td>
                <td>La Roche-Posay</td>
                <td>Lancôme</td>
                <td>L'Oreal Paris</td>
              </tr>
              <tr>
                <td>L'Oreal Professionnel</td>
                <td>Maison Martin Margiela</td>
                <td>Matrix</td>
                <td>Maybelline</td>
                <td>Pureology </td>
                <td>Ralph Lauren</td>
              </tr>
              <tr>
                <td>Roger &amp; Gallet</td>
                <td>shu uemura</td>
                <td>shu uemura Art of Hair</td>
                <td>SkinCeuticals</td>
                <td>Sanoflore</td>
                <td>Vichy</td>
              </tr>
              <tr>
                <td>Viktor &amp; Rolf</td>
                <td>Yves Saint Laurent Beauté</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />
          <h1>這私隱聲明的對象是誰？</h1>
          <br />
          本私隱聲明適用於所有歐萊雅香港有限公司(‘歐萊雅香港’)的品牌，其附屬或聯繫公司。
          <br />
          <br />
          本私隱聲明亦適用於收集個人資料的推廣活動、應用程式、網頁、網絡遊戲，以及其他歐萊雅香港的網頁(包括那些由歐萊雅香港管理及/或操控的網頁)
          (‘<b>媒體</b>
          ’)。本私隱聲明不適用於第三方網頁，包括那些我們的媒體以連結或其他相似方式提及或推介的網頁，因為我們不會對第三方網頁的處理私隱方式或其內容負責。惟我們鼓勵你在瀏覽其他網頁時，亦瀏覽其條文及條款。
          <br />
          <br />
          我們會不時更改本私隱聲明，亦會把更改的部分上載，讓你知道我們收集什麼資料、如何使用那些資料及我們在什麼情況下披露那些資料。我們會透過主網頁通知你。因此，請你經常閱讀本私隱聲明以得悉有關更改。
          <br />
          <br />
          <br />
          <h1>我們向你收集那些資料？</h1>
          <br />
          我們歡迎你光臨我們的分店和瀏覽我們的網頁，你無需向我們提供任何個人資料。如你選擇向我們提供個人資料，我們會在你選擇使用我們的媒體時，向你收集並處理所有或部分下列資料，例如，你在下列情況下提供的資料:
          <br />
          <br />
          (i) 填寫表格 (例如
          成為任何歐萊雅香港品牌登記使用者或會員，或你對任何網上的推廣活動有興趣，你需要透過我們的媒體提供聯絡資料);{' '}
          <br />
          <br />
          (ii) 填寫問卷 (例如 作研究和統計用);
          <br />
          <br />
          (iii) 上載資料 (例如
          在使用社交網絡媒體或當你參與網上推廣活動向我們提供的資料); <br />
          <br />
          (iv) 登記使用我們網上的服務(即 品牌網頁或應用程式和社交網絡網頁); 或
          <br />
          <br />
          (v) 與我們聯繫，包括:
          <br />
          <br />
          <div style={{ marginLeft: '25px' }}>
            *姓名; <br />
            *電郵地址; <br />
            *性別; <br />
            *電話號碼;
            <br />
            *通訊地址; <br />
            *年齡/ 生日的日期和月份;
            <br />
            *有關你對我們產品的看法;
            <br />
            *付款詳情 (即作網上交易媒體用);
            <br />
            *你向我們提出任何具體的要求(即 作記錄用);
            <br />
            *你想和大家分享的照片或錄影片。 <br />
          </div>
          <br />
          <br />
          <br />
          <h1>網頁 – 我們會否收集沒有個人資料的數據?</h1>
          <br />
          當你瀏覽我們的網頁或使用相關的網絡設施時，我們可能會收集你的IP地址和瀏覽器類型，作系統管理和/或統計用途，而不會識別你其他的個人資料。我們可能在你用作瀏覽的設備放置一
          ‘記憶體’，令你可使用我們的網頁。
          <br />
          <br />
          在你瀏覽我們的媒體時，你瀏覽的設備會下載及儲存一種叫‘記憶體’的細小數據。記憶體令歐萊雅香港在你瀏覽我們的媒體時識別到你的瀏覽設備，卻不會由你的瀏覽設備收集任何個人資料。
          <br />
          <br />
          我們主要用記憶體追蹤你在我們媒體的活動，以及在你下一次到訪時，識別你及向你展示一些你可能有興趣的內容。
          <br />
          <br />
          瀏覽器通常設計為可接受記憶體，惟你亦可很容易地更改你瀏覽器和/或你自己的設備在瀏覽互聯網時的設定。然而，請注意，如你選擇不接受記憶體，你可能無法使用我們媒體的部分功能，因而影響你使用我們的媒體。
          <br />
          <br />
          <br />
          <h1>我們如何使用你的資料？</h1>
          <br />
          我們主要只會使用你的資料與你保持聯繫，包括定期向你發放我們的產品資訊、品牌動向、活動和
          /
          或有關我們的新聞和信息，藉以了解你的個人選擇，及對任何要求或查詢作出回應(統稱為
          "<strong>市場推廣資訊</strong>")。
          <br />
          <br />
          我們亦會使用你的資料以提升我們向你提供的服務，以讓我們了解你對我們媒體內容的喜好，管理你的會籍或網上賬戶、驗證你的入會資格、處理你的付款，確保我們能夠向你和你的電腦提供最有效的媒體，避免虛假交易，以及保持我們媒體的安全。
          <br />
          <br />
          <br />
          <h1>你可如何查閱自已的個人資料? </h1>
          <br />
          你可以隨時以郵件、電郵（請參閱你使用的媒體的使用條款內列明的聯絡方法）聯絡我們，以找出我們擁有你什麼資料。
          <br />
          <br />
          如你發現資料有任何錯誤、或不完整或已過期，你也可以要求我們更正、補充或澄清。
          <br />
          <br />
          你亦可以要求我們刪除任何有關你的個人資料。但是，請注意，我們可能會保留你的資料記錄存檔（尤其是在法例要求的情況下）。
          <br />
          <br />
          <br />
          <h1>我們會否與他人共用你的資料？</h1>
          <br />
          除本私隱聲明所列明，或得到你事先的同意，我們不會與歐萊雅香港、歐萊雅中國或我們指定的服務供應商（如協助我們網頁管理的網絡管理商）之外的任何人士共用你的個人資料。
          <br />
          <br />
          我們的服務供應商只會使用你的資料，以提供我們要求他們提供的服務。我們亦要求這些業務的合作夥伴遵守私隱法，和要重視你的資料為機密資料。如你不欲提供任何個人資料，你可隨時向我們拒絕提供有關資料。我們不會把你的個人資料賣給第三者，而我們亦只會在仍有需要作收集你的資料的用途時，始會保留你的資料。
          <br />
          <br />
          為擴展業務所需，我們或需出售某些資產。在這些情況下，客戶的資料(包括個人資料)一般均為其中之一項被轉移的公司資產。透過使用和/或提供你的資料給我們或我們的媒體，你同意在上述情況下，所有你向我們提供的資料會被轉移。
          <br />
          <br />
          <br />
          <h1>我們把你的個人資料存放在那裏？</h1>
          <br />
          你的資料儲存在我們的數據庫內，而數據庫只有歐萊雅香港(或如適用的話，歐萊雅中國)的員工和合約服務供應商可以查閱。
          <br />
          <br />
          在某些情況下，而主要是基於技術原因，儲存數據庫的伺服器或會被放置在有別於你最初輸入資料的國家(包括香港以外的國家)。如有轉移你的資料到在資料保障方面，未有如本私隱聲明般嚴謹國家的風險，我們將採取合理的步驟，保證你的資料會以合乎本私隱聲明列明的方式處理。
          <br />
          <br />
          <br />
          <h1>你的個人資料安全嗎？</h1>
          <br />
          我們時刻以保障你的資料安全為目標。為此，我們採用多種安全技術，儘力防止你的資料遭受任何篡改、丟失，或遭未經授權的人士查閱你的資料。
          <br />
          <br />
          在我們的網上交易媒體上，我們以業界的標準方法（如PCI
          DSS）處理你的付款資料。
          <br />
          <br />
          由於無任何以互聯網傳送的資料可以100%安全，所以你在透過網上提供個人資料時務必加倍小心。因此，我們亦不可能保證你透過互聯網提供的任何資料完全安全。
          <br />
          <br />
          <br />
          <h1>語言及管轄的法例</h1>
          <br />
          歐萊雅香港完全根據香港法例第486章《個人資料(私隱)條例》處理你的個人資料。
          <br />
          <br />
          本私隱聲明受香港法例管轄，並以香港法例解釋。你同意，任何有關使用我們的服務及媒體的爭議，將受到香港非獨有管轄權所管轄。
          <br />
          <br />
          如本私隱聲明的中文版與英文版有任何岐義，應已英文版為準。
          <br />
          <br />
          <br />
          <h1>選擇/拒絕接受</h1>
          <br />
          為向你提供服務，歐萊雅香港只會使用你的個人資料向你提供市場推廣資訊，包括向你提供有關你的交易，及我們保安或媒體管理的資訊。
          <br />
          <br />
          在你的同意下，歐萊雅香港(或歐萊雅香港聯繫品牌或個體)將按照你個人的選擇，不時向你發出訊息或最新資料、推廣活動及其他有關活動。
          <br />
          <br />
          如你在任何時間不欲獲得任何歐萊雅市場推廣資訊，歡迎以電郵{' '}
          <a href="mailto: dppo@hkloreal.com">dppo@hkloreal.com</a> 通知我們。
          <br />
          <br />
          <br />
          <h1>如何聯絡我們? </h1>
          <br />
          如對我們的私隱聲明有任何問題，歡迎電郵{' '}
          <a href="mailto: dppo@hkloreal.com">dppo@hkloreal.com</a>{' '}
          聯絡我們的資料保障主任。
          <br />
          <br />
          你亦可選擇以郵寄方式要求查閱你的個人資料。我們的地址為: <br />
          <br />
          資料保障主任
          <br />
          香港銅鑼灣時代廣場1座34樓
          <br />
          歐萊雅香港有限公司
          <br />
          <br />
          <br />
        </div>
      ) : (
        <div className="bootstrap page-privacy">
          <div className="header-container">
            <h2 className="header-title">私隱政策</h2>
          </div>
          <p align="left">
            歐萊雅的抱負是成為「企業公民」的典範，協助令世界變得更美好。我們極其重視誠信和企業透明度，並致力奠基於信任和互惠的基礎，與我們的消費者建立穩固而長久的關係。保障和尊重閣下的私隱和選擇，是這份承諾蘊含的意義箇中一部分。對我們而言，尊重閣下的私隱至為重要；正因如此，我們於以下闡述「我們對私隱的承諾」，以及完整的私隱政策。
            <br />
            <br />
            我們對私隱的承諾
          </p>

          <ol>
            <li>我們尊重閣下的私隱和選擇。</li>
            <li>我們確保私隱和安全是深植於我們一切行事當中的基礎。</li>
            <li>
              除非閣下要求，否則我們不會向閣下發送市場推廣通訊。閣下可隨時改變主意。
            </li>
            <li>我們絕對不會供出或出售閣下的資料。</li>
            <li>我們致力保障閣下的資料安全穩妥；這包括只與可靠的夥伴合作。</li>
            <li>我們致力保持我們使用閣下資料的方式公開和透明。</li>
            <li>我們不會以未曾知會閣下的方式使用閣下的資料。</li>
            <li>
              我們尊重閣下的權利，並於符合我們的法律和營運責任的前提之下，一直致力盡量滿足閣下的要求。
            </li>
          </ol>

          <div>
            如欲了解更多有關我們的私隱守則的資訊，請查閱我們以下闡述的內容，包括我們可能直接向閣下接收或透過閣下與我們的互動而接收的個人資料類別、我們可能使用該等資料的方式、我們可能分享該等資料的對象、我們保護該等資料並保障其安全的方式，以及閣下關於個人資料的權利。誠然所有情況均未必適用於閣下；本私隱政策純粹讓閣下概覽我們或會進行互動的所有可能情況。
            <br />
            閣下與我們的互動愈頻繁，我們對閣下的了解亦會隨之加深，從而令我們更能為閣下提供度身訂造的服務。
            <br />
            當閣下與我們分享個人資料，或當我們向閣下收集個人資料時，我們將根據本政策使用該等個人資料。請仔細閱讀本資料以及我們的問答專頁
            (如有)。
            <br />
            閣下將可於本私隱政策知悉甚麼內容？
            <br />
            <a href="#who-are-we">我們的背景</a>
            <br />
            <a href="#what-is-personal-data">何謂個人資料？</a>
            <br />
            <a href="#what-data-do-we-collect">
              我們向閣下收集甚麼資料？我們又會如何使用該等資料？
            </a>
            <br />
            <div style={{ marginLeft: '40px' }}>
              <a href="#how-do-we-collect">我們如何收集或接收閣下的資料？</a>
              <br />
              <a href="#automated-decision-making">自動決策</a>
              <br />
              <a href="#profiling">個人剖析</a>
              <br />
              <a href="#who-may-access-your-personal-data">
                誰可存取閣下的個人資料？
              </a>
              <br />
              <a href="#where-we-store-your-personal-data">
                我們於哪裡存置閣下的個人資料？
              </a>
              <br />
              <a href="#how-long-do-we-keep-your-personal-data">
                我們保留閣下的個人資料多久？
              </a>
              <br />
              <a href="#is-my-Personal-data-secure">我的個人資料安全嗎？</a>
              <br />
              <a href="#links-to-third-party-sites">
                第三方網站連結和社交媒體登入
              </a>
              <br />
              <a href="#social-media">社交媒體和用戶生成內容</a>
              <br />
            </div>
            <a href="#your-rights">閣下的權利和選擇</a>
            <br />
            <a href="#contact">聯絡</a>
            <br />
            請注意，閣下必須年滿18歲，方可使用我們的服務。如特定服務另有要求使用者的年齡為18歲以上，該等要求將於相關條款中列明，閣下亦必須遵從。
          </div>
          <br />
          <h1 id="who-are-we">我們的背景 </h1>

          <p>
            BIOTHERM屬於歐萊雅香港有限公司旗下品牌組合的一部分。歐萊雅香港有限公司負責管理閣下與我們分享的個人資料。我們提及「歐萊雅」、「我們」、「我們的」或「本公司」時，所指的就是歐萊雅香港有限公司。就《個人資料
            <b>(私隱) 條例</b>
            (第486章)》而言，歐萊雅是一「資料使用者」。
          </p>

          <p>如欲查閱我們的聯絡詳情，請見「聯絡我們」部分。 </p>

          <p>
            歐萊雅代表多個不同品牌和產品。如欲了解更多關於歐萊雅及其代表品牌的資訊，請瀏覽
            <a href="http://www.loreal.hk/en-hk/">
              http://www.loreal.hk/en-hk/
            </a>
            。
          </p>

          <p>
            歐萊雅是於全球140個國家營運的歐萊雅集團的一部分。如欲了解歐萊雅集團的詳情，請瀏覽
            <a href="http://www.loreal.com/group">
              http://www.loreal.com/group
            </a>
            。
          </p>

          <h1 id="contact">聯絡</h1>
          <p>
            如閣下對於我們處理和使用閣下個人資料的方式有任何疑問或顧慮，或希望行使閣下上述任何權利，請電郵至
            <a href="mailto:biotherm.csc@loreal.com">biotherm.csc@loreal.com</a>
            或致函 與我們聯絡：{' '}
          </p>
          <p>
            香港特別行政區
            <br />
            灣仔港灣道30號
            <br />
            新鴻基中心35樓
            <br />
            歐萊雅香港有限公司
            <br />
            客戶服務部
            <br />
            致：BIOTHERM
          </p>
          <p>
            閣下亦可電郵至
            <a href="mailto:privacy.corphk@loreal.com">
              privacy.corphk@loreal.com
            </a>
            ，就有關閣下個人資料的處理方式提出任何疑問。
            <br />
            <br />
            <br />
          </p>

          <p className="text-center" style={{ fontSize: '20px' }}>
            <strong>BIOTHERM</strong>
            <br />
            <strong>私隱政策</strong>
          </p>
          <p></p>
          <h1 id="what-is-personal-data">何謂個人資料？ </h1>
          <p>
            「個人資料」意指任何可供直接 (例如閣下的姓名) 或間接
            (例如透過獨特編號等經過假名處理的資料)
            辨識閣下身分的單一或多條資訊。這意味著個人資料包括電郵、住宅地址、流動電話號碼、使用者名稱、個人檔案頭像和個人偏好，以及購物習慣、用戶生成內容、財務資訊，以及身體狀況資訊。這亦可能包括獨特數字識認，例如閣下的電腦IP位址或流動裝置的MAC網絡卡位址，以及cookies。
          </p>
          <h1 id="what-data-do-we-collect">
            我們向閣下收集甚麼資料？我們又會如何使用該等資料？{' '}
          </h1>
          <p>
            歐萊雅深信，閣下作為消費者，是我們服務的焦點對象。我們樂意聆聽閣下的意見和了解閣下，並為閣下創製和呈獻閣下喜歡的產品。我們亦深知許多消費者均熱衷於與我們對話；正因如此，我們提供多種方式，讓閣下可與我們分享閣下的個人資料，並讓我們可收集該等資料。
          </p>
          <h2 id="how-do-we-collect">我們如何收集或接收閣下的資料？ </h2>
          <p>
            我們或會透過我們的網站、表格、應用程式、裝置、歐萊雅產品、社交媒體上的品牌專頁或其他方式，向閣下收集或接收資料。該等資料或由閣下直接向我們提供
            (例如當閣下創建帳戶、聯絡我們，或於我們的網站/應用程式或專門店/美容院購物時)、由我們收集
            (例如利用cookies了解閣下如何使用我們的網站/應用程式)，或由我們透過其他第三方接收，包括歐萊雅集團的其他實體。
            <br />
            當我們收集資料時，我們以星號標示必填項目，皆因我們必需該等資料以供：
          </p>

          <ul>
            <li>
              我們履行與閣下的合約，例如付運閣下於我們的網站/應用程式上購買的產品；
            </li>
            <li>
              向閣下提供閣下要求的服務，例如向閣下提供最新消息、生日優惠或帳戶狀態；或
            </li>
            <li>遵從法律要求，例如開立發票。</li>
          </ul>

          <p>
            如閣下不提供以星號標示的資料，我們將可能無法提供相關產品和服務。
            <br />
            我們於下表列舉更多詳情，以闡釋：
          </p>

          <ol>
            <li>
              <strong>
                閣下於哪些互動當中可能需要提供或由我們收集個人資料？
              </strong>
              此欄闡釋我們使用或收集閣下的資料時閣下正參與的活動或情況，例如進行購物、登記收取最新消息，或瀏覽網站/應用程式。
            </li>
            <li>
              <strong>
                我們可向閣下直接收集或透過閣下與我們的互動接收哪些個人資料？
              </strong>
              此欄闡釋我們視乎情況而可能向閣下收集的資料類別。
            </li>
            <li>
              <strong>我們會如何使用該等資料？何解？</strong>
              此欄闡釋我們可能處理閣下資料的方法，以及收集該等資料之目的。
            </li>
            <li>
              <strong>使用閣下個人資料的法律基礎為何？</strong>
              本欄闡釋我們或會使用閣下資料的原因。
            </li>
          </ol>

          <p>視乎使用資料之目的而定，處理閣下資料的法律基礎可為：</p>

          <ul>
            <li>
              閣下的<strong>同意；</strong>
            </li>
            <li>
              我們的<strong>合法利益</strong>，當中可為：
              <ul>
                <li>
                  <strong>改善我們的產品和服務：</strong>
                  更具體而言，是指我們的商業利益，協助我們更清楚理解閣下的需要和期望，從而改善我們的服務、網站/應用程式/裝置、產品和品牌，讓我們的消費者有所裨益。
                </li>
                <li>
                  <strong>防止詐騙：</strong>確保付款完成，免於詐騙和挪用金錢。
                </li>
                <li>
                  <strong>保障我們的工具安全：</strong>保障閣下所使用的工具
                  (我們的網站/應用程式/裝置)
                  安全穩妥，並確保它們正常運作和不斷改良。
                </li>
              </ul>
            </li>
            <li>
              <strong>履行合約：</strong>
              更具體而言，是指履行閣下向我們要求的服務；或
            </li>
            <li>
              <strong>法律依據，</strong>適用於法律要求處理閣下個人資料的情況。
            </li>
          </ul>
          <table
            border="0"
            cellPadding="0"
            cellSpacing="0"
            className="table table-bordered table-responsive"
            width="0"
          >
            <tbody>
              <tr>
                <td colSpan="4" valign="top">
                  <p>
                    <u>
                      關於閣下與我們的互動以及其對閣下資料構成的影響之資訊概覽
                    </u>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    <strong>
                      <em>
                        閣下於哪些互動當中可能需要提供或由我們收集閣下的資料？
                      </em>
                    </strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    <strong>
                      <em>
                        我們可向閣下直接收集或透過閣下與我們的互動接收哪些個人資料？
                      </em>
                    </strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    <strong>
                      <em>我們會如何使用閣下的資料？何解？</em>
                    </strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    <strong>
                      <em>使用閣下個人資料的法律基礎為何？</em>
                    </strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td rowSpan="3" valign="top">
                  <p>
                    <strong>創建和管理帳戶</strong>
                    <strong> </strong>
                  </p>

                  <p>
                    於歐萊雅網站/應用程式上、透過社交媒體登入，或於專門店創建帳戶時所收集的資料。
                  </p>
                </td>
                <td rowSpan="3" valign="top">
                  <p>視乎閣下與我們互動的多寡，該些資料可能包括：</p>

                  <ul>
                    <li>姓氏及稱謂；</li>
                    <li>性別；</li>
                    <li>電郵地址；</li>
                    <li>地址；</li>
                    <li>電話號碼；</li>
                    <li>相片；</li>
                    <li>生日或年齡組別；</li>
                    <li>帳戶編號、使用者名稱和密碼；</li>
                    <li>個人描述或偏好；</li>
                    <li>訂單詳情；以及</li>
                    <li>
                      社交媒體檔案
                      (如閣下使用社交媒體登入，或與我們分享是項個人資料)。
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>以供：</p>

                  <ul>
                    <li>管理閣下的訂單；</li>
                    <li>
                      管理任何閣下選擇參與的比賽、推廣活動、意見調查或抽獎；
                    </li>
                    <li>回應閣下的疑問，或於其他情況與閣下互動；</li>
                    <li>為閣下呈獻常客計劃；</li>
                    <li>容許閣下管理個人偏好；</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>履行合約</strong>
                      <p>
                        向閣下提供閣下所要求的服務，例如創建帳戶、填妥意見調查或購買產品。
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>
                      向閣下發送市場推廣通訊
                      (如閣下如此要求)，而該等通訊或會根據閣下的「個人檔案」
                      (亦即基於我們所知悉的閣下個人資料和偏好) 度身訂造；
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>同意</strong>
                      <p>向閣下發送直接促銷通訊。</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>根據閣下的美妝特徵呈獻度身訂造的服務；</li>
                    <li>監察和改良我們的網站/應用程式；</li>
                    <li>進行分析或收集統計數據；以及</li>
                    <li>
                      保障我們的網站/應用程式安全，並保護閣下和我們免遭詐騙；
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>合法利益</strong>
                      <p>
                        確保我們的網站/應用程式保持安全，保障其免受詐騙影響，並協助我們更清楚理解閣下的需要和期望，從而改善我們的服務、產品和品牌。
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="3" valign="top">
                  <p>
                    <strong>訂閱最新消息和商業通訊</strong>
                  </p>
                </td>
                <td rowSpan="3" valign="top">
                  <p>視乎閣下與我們互動的多寡，該些資料可能包括：</p>

                  <ul>
                    <li>電郵地址；</li>
                    <li>姓氏及稱謂；</li>
                    <li>個人描述或偏好；以及</li>
                    <li>
                      社交媒體檔案
                      (如閣下使用社交媒體登入，或與我們分享是項個人資料)。
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <p>
                    以供：
                    <br />
                    向閣下發送市場推廣通訊
                    (如閣下如此要求)，而該等通訊或會根據我們所知悉的閣下個人資料和偏好
                    (包括閣下最愛的專門店之所在地)
                    建立的「個人檔案」度身訂造；以及
                  </p>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>同意</strong>
                      <p>向閣下發送直接促銷通訊。</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>進行分析或收集統計數據。</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>合法利益</strong>
                      <p>
                        為閣下度身訂造我們的市場推廣通訊、了解其效用，並確保閣下獲取最切身的體驗；以及協助我們更清楚理解閣下的需要和期望，從而改善我們的服務、產品和品牌。
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>
                      如閣下要求我們終止聯絡閣下，更新包括閣下在內的拒收電郵名單；
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>法律依據</strong>
                      <p>
                        如閣下要求我們不再向閣下發送任何直接促銷資訊，我們會將閣下的詳細資料保存至拒收電郵名單。
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="2" valign="top">
                  <p>
                    <strong>購物和訂單管理</strong>
                  </p>

                  <p>
                    於歐萊雅網站/應用程式/社交媒體專頁或專門店進行購物的過程期間所收集的資訊
                  </p>
                </td>
                <td rowSpan="2" valign="top">
                  <p>視乎閣下與我們互動的多寡，該些資料可能包括：</p>

                  <ul>
                    <li>姓氏及稱謂；</li>
                    <li>電郵地址；</li>
                    <li>地址 (送貨和開立發票)；</li>
                    <li>電話號碼；</li>
                    <li>個人描述或偏好；</li>
                    <li>
                      社交媒體檔案
                      (如閣下使用社交媒體登入，或與我們分享是項個人資料)；
                    </li>
                    <li>交易資訊，包括已購買的產品和專門店地址；</li>
                    <li>付款狀態和資訊；或</li>
                    <li>付款紀錄</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>以供</p>

                  <ul>
                    <li>
                      於閣下已儲存購物車內容，或將產品放進購物車而尚未完成結賬程序時，聯絡閣下以結算訂單；
                    </li>
                    <li>當閣下心儀的產品可供購買時通知閣下；</li>
                    <li>
                      處理和跟進閣下的訂單，包括將產品付運至閣下指示的地址；
                    </li>
                    <li>
                      管理閣下訂單的付款狀態。請注意，付款資訊
                      (信用卡號碼/Paypal、支付寶或微信支付資訊/銀行賬戶詳情)
                      並非由我們收集，而是由支付服務提供者直接收集；
                    </li>
                    <li>管理閣下就閣下的訂單向我們提供的任何聯絡資料；</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>履行合約：</strong>
                      <p>向閣下提供閣下所要求的 (購物) 服務。</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>
                      保障交易免受詐騙影響。請注意，我們使用第三方提供者的方案以偵測詐騙，並確保付款由閣下或閣下授權的人士進行和完成；
                    </li>
                    <li>
                      如閣下使用閣下的帳戶資訊進行購物，為閣下的個人檔案新增內容；
                    </li>
                    <li>評估閣下的滿意度；</li>
                    <li>管理任何與訂單相關的糾紛；以及</li>
                    <li>進行統計用途。</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>合法利益</strong>
                      <p>
                        保障閣下和我們免遭欺詐交易影響，並確保付款完成，免於詐騙和挪用金錢。
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="2" valign="top">
                  <strong>網上瀏覽</strong>
                  <p>
                    閣下於瀏覽歐萊雅網站/應用程式及/或第三方網站/應用程式期間，利用cookies或類近科技
                    (下稱「<strong>Cookies</strong>」*) 所收集的資訊。
                  </p>

                  <p>
                    如欲了解透過某網站/應用程式設置的特定Cookies資訊，請查閱相關的Cookies列表。
                  </p>

                  <p>
                    *
                    Cookies是當閣下瀏覽互聯網，包括歐萊雅集團的網站時，儲存於閣下的
                    (電腦、平板電腦或流動電話) 裝置裡的小型文字檔案。
                  </p>
                </td>
                <td rowSpan="2" valign="top">
                  <p>視乎閣下與我們互動的多寡，該些資料可能包括：</p>

                  <ul>
                    <li>關於閣下使用我們的網站/應用程式的資料：</li>
                    <li>閣下從何網站而來；</li>
                    <li>登入詳情；</li>
                    <li>閣下曾瀏覽的頁面；</li>
                    <li>閣下曾觀看的短片；</li>
                    <li>閣下點擊或按下的廣告；</li>
                    <li>閣下搜尋的產品；</li>
                    <li>閣下的所在地；</li>
                    <li>閣下逗留的時間；以及</li>
                    <li>閣下選擇用以創建個人購物車的產品。</li>
                  </ul>

                  <p>技術資訊：</p>

                  <ul>
                    <li>IP位址；</li>
                    <li>瀏覽器資訊；以及</li>
                    <li>裝置資訊。</li>
                  </ul>

                  <p>授予每位訪問者的獨特識別碼，以及該等識別碼的屆滿日期。</p>
                </td>
                <td valign="top">
                  <p>
                    如有必要，我們將利用Cookies，連同閣下已與我們分享的其他個人資料
                    (例如過往訂單，或閣下對於我們的最新消息電郵的訂閱狀態)，以達到以下目的：
                  </p>

                  <ul>
                    <li>
                      容許我們的網站/應用程式正常運作，包括：
                      <ul>
                        <li>正常顯示內容；</li>
                        <li>創建和記錄購物車；</li>
                        <li>創建和記錄閣下的登入資訊；</li>
                        <li>提供個人專屬介面，例如語言；</li>
                        <li>
                          閣下的裝置附載的參數，包括閣下的螢幕解像度等；以及
                        </li>
                        <li>改善我們的網站/應用程式，例如測試嶄新構思；</li>
                      </ul>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      確保網站/應用程式安全穩妥，例如透過進行疑難排解，保護閣下免遭詐騙或不當使用我們的網站或服務；
                    </li>
                  </ul>

                  <ul>
                    <li>
                      進行統計：
                      <ul>
                        <li>避免同一訪問者被重覆記錄；</li>
                        <li>了解使用者對我們的廣告企劃的反應；</li>
                        <li>改善我們的優惠；</li>
                        <li>了解閣下如何知悉我們的網站/應用程式。</li>
                      </ul>
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>合法利益：</strong>
                      <p>
                        確保我們向閣下提供正常運作的網站/應用程式、廣告和通訊，並持續改良
                        (i) 我們的網站/應用程式運作時所必需，以及 (ii)
                        保障我們的網站/應用程式安全穩妥的cookies。
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>
                      提供網上行為定向廣告：
                      <ul>
                        <li>
                          根據閣下過往的行為模式，向閣下展示閣下可能感興趣的產品的網上廣告；以及
                        </li>
                        <li>於社交媒體平台向閣下展示廣告和內容。</li>
                      </ul>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      為閣下提供度身訂造的服務：
                      <ul>
                        <li>
                          根據閣下的個人檔案和興趣，向閣下發送產品推薦、市場推廣信息或內容；
                        </li>
                        <li>
                          以度身訂造的方式顯示我們的網站/應用程式，例如記錄閣下的購物車或登入資訊、閣下使用的語言，以及使用者介面自定義cookies
                          (即閣下的裝置附載的參數，包括閣下的螢幕解像度和字型偏好等)；以及
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      容許使用者於社交媒體上分享我們提供的內容
                      (為了展示網站而設的分享按鈕)。
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>同意</strong>
                      <p>所有其他cookies。</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="3" valign="top">
                  <p>
                    <strong>推廣活動</strong>
                  </p>

                  <p>
                    於遊戲、比賽、推廣優惠、索取試用裝和意見調查期間所收集的資訊。
                  </p>
                </td>
                <td rowSpan="3" valign="top">
                  <p>視乎閣下與我們互動的多寡，該些資料可能屯括：</p>

                  <ul>
                    <li>姓氏及稱謂；</li>
                    <li>電郵地址；</li>
                    <li>電話號碼；</li>
                    <li>出生日期；</li>
                    <li>性別；</li>
                    <li>地址；</li>
                    <li>個人描述或偏好；</li>
                    <li>
                      社交媒體檔案
                      (如閣下使用社交媒體登入，或與我們分享是項個人資料)；以及
                    </li>
                    <li>
                      閣下與我們分享的其他與個人相關的資訊，例如透過閣下的「我的帳戶」頁面、聯絡我們、提供個別內容如相片或評論、透過部分網站/應用程式提供的對話功能發問，或參加比賽、遊戲或意見調查。
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      完成閣下要求我們執行的指令，例如管理閣下於比賽、遊戲和意見調查的參與，包括考慮閣下的意見和建議；
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>履行合約</strong>
                      <p>向閣下提供閣下所要求的服務。</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>進行統計用途；以及</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>合法利益</strong>
                      <p>
                        協助我們更清楚理解閣下的需要和期望，從而改善我們的服務、產品和品牌。
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>向閣下發送市場推廣通訊 (如閣下如此要求)</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>同意</strong>
                      <p>向閣下發送直接促銷通訊。</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="2" valign="top">
                  <p>
                    <strong>用戶生成內容</strong>
                  </p>

                  <p>
                    當閣下於我們的社交平台提交內容，或同意讓我們重用閣下於社交媒體平台張貼的內容時所收集的資訊。
                  </p>
                </td>
                <td rowSpan="2" valign="top">
                  <p>視乎閣下與我們互動的多寡，該些資料可能包括：</p>

                  <ul>
                    <li>姓氏及稱謂或別名；</li>
                    <li>電郵地址；</li>
                    <li>相片；</li>
                    <li>個人描述或偏好；</li>
                    <li>
                      社交媒體檔案
                      (如閣下使用社交媒體登入，或與我們分享是項個人資料)；以及
                    </li>
                    <li>
                      閣下與我們分享的其他與個人相關的資訊，例如透過閣下的「我的帳戶」頁面、聯絡我們、提供個別內容如相片或評論，或透過部分網站/應用程式提供的對話功能發問。
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      根據閣下所接受的特定條款及細則：
                      <ul>
                        <li>張貼閣下的評論或內容；以及</li>
                        <li>推廣我們的產品。</li>
                      </ul>
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>同意</strong>
                      <p>重用閣下於網上張貼的內容。</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>進行統計用途。</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>合法利益</strong>
                      <p>
                        協助我們更清楚理解閣下的需要和期望，從而改善我們的服務、產品和品牌。
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="2" valign="top">
                  <p>
                    <strong>使用應用程式和裝置</strong>
                  </p>

                  <p>閣下使用我們的應用程式及/或裝置期間所收集的資訊。</p>
                </td>
                <td rowSpan="2" valign="top">
                  <p>視乎閣下與我們互動的多寡，該些資料可能包括：</p>

                  <ul>
                    <li>姓氏及稱謂；</li>
                    <li>電郵地址；</li>
                    <li>所在地；</li>
                    <li>出生日期；</li>
                    <li>個人描述或偏好；</li>
                    <li>相片；</li>
                    <li>身體狀況資料，包括膚色和皮膚/頭髮類型；以及</li>
                    <li>地理位置。</li>
                  </ul>
                </td>
                <td valign="top">
                  <p>以供</p>

                  <ul>
                    <li>
                      向閣下提供要求的服務，例如虛擬測試我們的產品、透過應用程式或於相關電子商務網站購買我們的產品；關於閣下的日曬狀況和護髮程序的建議和通知；
                    </li>
                    <li>
                      分析閣下的身體狀況特徵，並推薦適合的產品
                      (包括度身訂造的產品) 和護理程序；
                    </li>
                    <li>向閣下提供產品和護理程序推薦；</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>履行合約</strong>
                      <p>
                        向閣下提供要求的服務，包括於需要時由
                        <a
                          href="http://www.loreal.hk/zh-hk/%E7%A0%94%E7%A9%B6%E5%8F%8A%E5%89%B5%E6%96%B0"
                          target="_blank"
                          rel="noreferrer"
                        >
                          研究及創新
                        </a>
                        團隊分析提供服務的必要演算序列。
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>於歐萊雅集團內部供科學家進行研究和創新；</li>
                    <li>監察和改善我們的應用程式和裝置；以及</li>
                    <li>進行統計用途。</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>合法利益</strong>
                      <p>
                        持續改善我們的產品和服務以符合閣下的需要和期望，並供進行研究和創新用途。
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td rowSpan="2" valign="top">
                  <p>
                    <strong>查詢</strong>
                    <strong> </strong>
                  </p>

                  <p>
                    當閣下提出關於我們的品牌、產品及其使用方法的疑問時
                    (例如透過我們的顧客服務渠道) 所收集的資料。
                  </p>
                </td>
                <td rowSpan="2" valign="top">
                  <p>視乎閣下與我們互動的多寡，該些資料可能包括：</p>

                  <ul>
                    <li>姓氏及稱謂；</li>
                    <li>電話號碼；</li>
                    <li>電郵地址；以及</li>
                    <li>
                      閣下與我們分享而有關閣下查詢的其他個人資訊
                      (可能包括身體狀況和健康資料)。
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>回覆閣下的查詢；</li>
                    <li>於有需要時，將閣下轉介至相關服務；</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>同意</strong>
                      <p>處理閣下的查詢。</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <ul>
                    <li>進行統計用途；以及</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>合法利益</strong>
                      <p>
                        協助我們更清楚理解我們的顧客的需要和期望，從而改善我們的服務、產品和品牌。
                      </p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">&nbsp;</td>
                <td valign="top">&nbsp;</td>
                <td valign="top">
                  <ul>
                    <li>
                      進行產品推出後的安全監測：
                      <ul>
                        <li>
                          監察與防止任何與使用我們的產品有所連繫的不良作用；
                        </li>
                        <li>進行關於安全使用我們的產品的研究；以及</li>
                        <li>於有需要時實行和跟進所採取的糾正措施。</li>
                      </ul>
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>法律依據</strong>
                      <p>遵從法律義務，以監察產品的任何不良作用。</p>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    <strong>推薦</strong>
                  </p>
                </td>
                <td valign="top">
                  <p>視乎閣下與我們互動的多寡，該些資料可能包括：</p>

                  <ul>
                    <li>姓氏及稱謂；</li>
                    <li>電話號碼；以及</li>
                    <li>電郵地址。</li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      按照使用者的要求，將我們的產品資訊及/或標記於願望清單的資訊發送予他人。
                    </li>
                  </ul>
                </td>
                <td valign="top">
                  <ul>
                    <li>
                      <strong>履行合約</strong>
                      <p>處理相關要求。</p>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <strong>合法利益</strong>

                      <p>按照某位使用者的要求聯絡他人。</p>
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <hr />
          <h2 id="automated-decision-making">
            <strong>自動決策</strong>
          </h2>

          <p>
            我們利用第三方提供者的一個或多個方案，以供保障透過於我們的網站/應用程式/裝置進行的交易免於詐騙或挪用金錢。詐騙偵測是以簡單比較、聯繫、聚類分析、預計和異常檢測等方法為基礎，利用智能媒介、資料融合技術和多種資料探勘技術進行。
            <br />
            這種詐騙偵測過程可完全自動進行，或涵蓋由人們作出最終決定的人為干預。於任何情況下，我們均採取一切合理的預防和保障措施，限制存取閣下的資料。
          </p>
          <p>
            由於需要進行自動詐騙偵測，閣下可能 (i)
            當閣下的交易正由我們審核時，經歷訂單/要求處理延誤；以及 (ii)
            一旦鑒定詐騙風險，被限制或豁免受惠於某項服務。閣下有權存取我們用以作為決定基礎的資訊。請見以下「閣下的權利和選擇」部分。
          </p>

          <h2 id="profiling">個人剖析</h2>

          <p>
            當我們發送或顯示度身訂造的通訊或內容時，我們或會採用符合「個人剖析」的部分技術，亦即任何自動處理個人資料的形式，涵蓋利用該等資料評估關於某位自然人的相關個人特點，尤其是用以分析或預計關於該位自然人的個人偏好、興趣、經濟狀況、行為、所在地、健康、可靠度或行動的特點。這意味著我們或會於上表所列的不同情況當中收集關於閣下的個人資料。我們集中處理和分析此項資料，以評估和預測閣下的個人偏好及/或興趣。
            <br />
            根據我們的分析，我們將發送或顯示按照閣下的興趣/需要度身訂造的通訊及/或內容。
          </p>

          <p>
            閣下有權反對於特定情況使用閣下的資料進行「個人剖析」。請見下列「閣下的權利和選擇」部分。
          </p>
          <h2 id="who-may-access-your-personal-data">
            誰可存取閣下的個人資料？
          </h2>

          <p>
            <strong>
              為了遵從我們的法律義務、防止詐騙及/或保障我們的工具安全並改善我們的產品和服務，或於取得閣下同意時，我們或會將閣下的個人資料於歐萊雅集團內部分享。
            </strong>
            <br />
            視乎收集資料之目的，以及僅以需知為基礎的情況下，閣下的部分個人資料或會於必要時由全球歐萊雅集團實體存取，以供為閣下提供要求的服務，而該等資料將於可能情況下以假名方式處理
            (防止直接辨識身分)。
          </p>

          <p>
            我們亦可能以假名處理方式 (防止直接辨識身分) 與歐萊雅研究及創新部門
            (包括位於閣下所在國家以外的地區)
            的科學家分享閣下的個人資料，以供研究和創新用途。
          </p>

          <p>
            於許可的情況下，我們亦可能於旗下品牌之間分享閣下部分個人資料，包括透過Cookies所收集的資料，以統一和更新閣下與我們分享的資訊、根據閣下的特徵進行統計，並為閣下度身訂造我們的通訊。
          </p>

          <p>
            關於<a href="https://www.loreal.com/group">歐萊雅集團</a>、其
            <a href="http://www.loreal.com/brand">品牌</a>和
            <a href="http://www.loreal.com/group/our-activities/l%E2%80%99or%C3%A9al-around-the-world">
              所在地
            </a>
            的更多詳情，請瀏覽歐萊雅集團網站。
          </p>

          <ul>
            <li>
              <strong>
                我們或會與第三方或歐萊雅集團實體分享閣下的個人資料，以作市場推廣用途。
              </strong>
            </li>
            <li>
              我們僅於取得閣下同意後，方與第三方分享閣下的個人資料以作直接促銷用途。於此情況下，閣下的資料將由作為資料使用者的該等第三方處理，並受其個別條款及細則和私隱通知約束。閣下應仔細檢閱彼等的文件，方同意將個人資訊披露予該等第三方。
            </li>
          </ul>
          <p>
            <strong>
              閣下的個人資料亦可能由我們的可靠第三方提供者以我們的名義處理。
            </strong>
          </p>

          <p>
            我們倚賴可靠第三方以我們的名義進行一系列業務營運工作。我們僅向彼等提供其執行服務所需的資料，並要求彼等不使用閣下的個人資料作任何其他用途。我們一直竭盡所能，確保我們合作的所有第三方均保障閣下的個人資料安全。例如，我們或會向以下各方委託服務，而該等服務需要處理閣下的個人資料：
          </p>
          <ul>
            <li>
              支援和協助我們提供數碼和電子商務服務的第三方，例如社群聆聽、專門店一覽、常客計劃、身分管理、評價和評論、顧客關係管理、網絡數據分析和搜尋器，以及用戶生成內容策展工具；
            </li>
            <li>
              廣告、市場推廣、數碼和社交媒體代理公司，協助我們提供廣告、市場推廣和企劃，並分析其效用，以及管理閣下的聯絡資料和疑問；
            </li>
            <li>奉命將產品運送予閣下的第三方，例如郵政/付運服務；</li>
            <li>
              支援和協助我們提供資訊科技服務的第三方，例如平台提供者、網站寄存服務、為我們的資料庫以及可能包含閣下資料的軟件和應用程式進行的維護和支援
              (該等服務意味著閣下的資料有時可能需要被存取，以執行所需任務)；
            </li>
            <li>
              支付服務供應者和信貸資料服務機構，以供於與閣下締約的情況當中，評估閣下的信貸評分和確認閣下的詳細資料；以及
            </li>
            <li>協助我們處理顧客服務和產品推出後的安全監測的第三方。</li>
          </ul>

          <p>
            <strong>我們亦可能將閣下的個人資料披露予第三方：</strong>
          </p>

          <ul>
            <li>
              倘我們出售任何業務或資產，我們或會向該等業務或資產的潛在買家披露閣下的個人資料。如歐萊雅或其部分資產被第三方收購，歐萊雅持有之與該等資產相關的顧客個人資料將成為轉移資產的一部分。於該等情況下
              (如適用)，買家將以新的資料使用者身分處理閣下的資料，並受其個別私隱政策管轄；
            </li>
            <li>
              如我們為了遵從法律義務、執行或應用我們的使用/銷售條款或其他閣下已同意之條款及細則；或保障歐萊雅、我們的顧客或僱員的權利、財產或安全，而有責任披露或分享閣下個人資料；
            </li>
            <li>如我們已取得閣下同意如此行事；或</li>
            <li>如法律許可我們如此行事。</li>
          </ul>

          <p>
            <strong>我們可能將閣下的個人資料披露予我們的合作夥伴：</strong>
          </p>

          <ul>
            <li>
              倘閣下利用的服務由歐萊雅及其合作夥伴共同創設
              (例如品牌聯乘應用程式)。在該等情況下，歐萊雅及該夥伴將各自以其個別目的處理閣下的個人資料，而因此閣下的個人資料將：
              <ul>
                <li>由歐萊雅根據本私隱政策處理；以及</li>
                <li>
                  由該夥伴同樣以資料使用者的身分，在其個別條款及細則的規範下，根據其個別私隱政策處理；
                </li>
              </ul>
            </li>
            <li>
              倘閣下已透過專屬選項
              (例如透過由歐萊雅訂立品牌並提供予其合作夥伴的應用程式)
              同意接收由歐萊雅合作夥伴發送的市場推廣和商業通訊。於此等情況下，閣下的資料將由該夥伴以資料使用者的身分，在其個別條款及細則的規範下，根據其個別私隱政策處理；以及
            </li>
            <li>
              我們或會刊登來自社交網絡的輔助內容。倘閣下於我們的網站/應用程式查閱來自社交網絡的內容，來自該等社交網絡的Cookie可能會儲存於閣下的裝置。我們懇請閣下閱讀此等社交網絡的Cookie政策以了解更多資訊。
            </li>
          </ul>

          <p>
            <strong>我們不會供出或出售閣下的個人資料。</strong>
          </p>

          <h2 id="where-we-store-your-personal-data">
            我們於哪裡存置閣下的個人資料
          </h2>

          <p>
            我們向閣下收集的資料或會轉移至和存置於香港特別行政區以外的地點，並由該等地點存取。該等資料亦可能由我們或我們的服務提供者於香港特別行政區以外地點工作的員工處理。
          </p>

          <p>
            歐萊雅僅以安全而合法的方式將資料轉移至香港特別行政區以外地點。由於部分國家或不設管轄個人資料之應用和轉移的法律，我們採取必要步驟，確保第三方遵守於本政策列明的承諾。此等步驟可能包括審核第三方的私隱和保安標準，及/或與之締結適當合約。
            <br />
            如欲了解更多資訊，請按照以下「聯絡」部分的方式聯絡我們。
          </p>

          <h2>
            <p name="_Toc499630199"></p>
          </h2>
          <h2 id="how-long-do-we-keep-your-personal-data">
            我們保留閣下的個人資料多久
          </h2>

          <p>
            我們保留閣下個人資料的期限，僅限於我們需要持有該等資料以迎合閣下的需要或遵從我們的法律義務的期間。
            <br />
            我們利用以下準則，界定保留閣下資料的期限：
          </p>

          <ul>
            <li>
              如閣下購買產品和服務，我們將於雙方的契約關係生效期間保留閣下的個人資料；
            </li>
            <li>
              如閣下參與推廣優惠，我們將於該推廣優惠期間保留閣下的個人資料；
            </li>
            <li>
              如閣下聯絡我們進行查詢，我們將於處理閣下查詢的必要期間內保留閣下的個人資料；
            </li>
            <li>
              如閣下創建帳戶，我們將保留閣下的個人資料，直至閣下要求我們將該等資料刪除，或經過根據當地法規和指示所定義的凍結期間後
              (沒有與品牌主動進行互動)；
            </li>
            <li>
              如閣下同意接收直銷信息，我們將保留閣下的個人資料，直至閣下取消訂閱或要求我們刪除該等資料，或經過根據當地法規和指示所定義的凍結期間後
              (沒有與品牌主動進行互動)；以及
            </li>
            <li>
              如cookies儲存於閣下的電腦，我們將於它們發揮作用的必要期間內
              (例如購物車cookies的工作階段期限或工作階段識別碼cookies)，並於其後根據當地法規和指示所定義的期限內保留該等cookies。
            </li>
          </ul>

          <p>
            我們或會保留部分個人資料，以遵從我們的法律或監管義務，並容許我們管理我們的權利
            (例如於法庭提出申索)，或作統計或記錄用途。
          </p>

          <p>
            當我們不再需要使用閣下的個人資料，該等資料將從我們的系統和記錄移除或以匿名方式處理，從而令閣下不再從中被辨識。
          </p>

          <h2 id="is-my-Personal-data-secure">閣下的個人資料安全嗎？</h2>

          <p>
            我們致力保障閣下的個人資料安全，並為此採取一切合理預防措施。我們亦以締約方式要求為我們處理閣下個人資料的可靠第三方遵從此守則。
          </p>

          <p>
            我們竭盡所能保護閣下的個人資料，而每當我們接收閣下的個人資料後，都利用嚴謹程序和保安措施，嘗試防止未經授權的存取。由於透過互聯網傳送資料並非絕對安全，我們無法保證閣下傳送至我們網站的資料安全。因此，閣下必需自行承受任何資料傳送的風險。
          </p>
          <h2 id="links-to-third-party-sites">第三方網站連結和社交媒體登入</h2>

          <p>
            我們的網站和應用程式或會不時含有連結，來往我們的合作夥伴網絡、廣告商和聯營企業的網站。如閣下開啟連結前往任何此等網站，請注意此等網站另有其個別私隱政策，而我們對於此等政策將不會負上任何義務或法律責任。請於提交任何個人資料至此等網站前檢閱此等政策。
          </p>

          <p>
            我們亦可能會為閣下提供使用社交媒體登入的渠道。請注意，如閣下使用社交媒體登入，視乎閣下的社交媒體平台設定，閣下或將與我們分享閣下的個人檔案資訊。請到訪相關社交媒體平台並檢閱其私隱政策，以了解閣下的個人資料於此等情況下如何被分享和使用。
          </p>
          <h2 id="social-media">社交媒體和用戶生成內容</h2>

          <p>
            我們的部分網站和應用程式容許使用者提交其個人內容。請緊記提交至我們的社交媒體平台之任何內容均可供公眾閱覽，因此閣下於提供若干個人資料，例如財務資訊或地址詳情時，應當小心謹慎。如閣下於我們的社交媒體平台張貼個人資料，而我們並不建議閣下分享該等資料，我們對於其他個別人士採取的任何行動概不負責。
          </p>

          <h1 id="your-rights">
            <p name="PP_ThirdPartySiteCookies"></p>
            <p name="PP_CookiesConsent"></p>
            <p name="PP_AccuracyOfYourPersonalInformation"></p>閣下的權利和選擇
          </h1>

          <p>
            歐萊雅尊重閣下就個人私隱所擁有的權利；閣下能夠掌控個人資料至為重要。閣下擁有以下權利：
          </p>
          <table
            border="0"
            cellPadding="0"
            cellSpacing="0"
            className="table table-bordered table-responsive"
            width="0"
          >
            <tbody>
              <tr>
                <td valign="top">
                  <p>
                    <strong>
                      <em>閣下的權利</em>
                    </strong>
                  </p>
                </td>
                <td valign="top">
                  <p>
                    <strong>
                      <em>這意味著甚麼？</em>
                    </strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    被<strong>知會</strong>的權利
                  </p>
                </td>
                <td valign="top">
                  <p>
                    閣下有權就我們使用閣下個人資料的方式，以及閣下的權利，獲取清晰、透明而易於理解的資訊。這正是我們於本政策向閣下提供此等資訊的原因。
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    <strong>存取</strong>的權利
                  </p>
                </td>
                <td valign="top">
                  <p>
                    閣下有權存取由我們持有而與閣下有關的個人資料
                    (受特定限制約束)。
                    <br />
                    我們或會視乎提供該等資訊的行政成本而收取合理費用。
                    <br />
                    明顯缺乏根據、過量或重覆的要求或不獲回覆。
                    <br />
                    如欲存取閣下的個人資料，請透過下列詳情聯絡我們。
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    <strong>更正</strong>的權利
                  </p>
                </td>
                <td valign="top">
                  <p>
                    如閣下的個人資料有誤或過時及/或出現缺漏，閣下有權將之更正及/或補充。
                    <br />
                    如欲進行，請透過下列詳情聯絡我們。如閣下擁有帳戶，請透過閣下帳戶的「我的帳戶」功能自行更正閣下的個人資料。
                  </p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    <strong>反對包括個人剖析在內的直銷</strong>的權利
                  </p>
                </td>
                <td valign="top">
                  <p>
                    閣下可隨時取消訂閱我們的直銷通訊或從相關清單退出。
                    <br />
                    最簡易的做法是點擊我們向閣下發送的任何電郵或通訊裡的「取消訂閱」連結。否則，閣下亦可利用下列的聯絡詳情與我們聯絡。
                  </p>

                  <p>如閣下欲反對任何個人剖析，請透過以下詳情聯絡我們。</p>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <p>
                    <strong>隨時撤銷根據閣下同意進行的資料處理之同意</strong>
                    的權利
                  </p>
                </td>
                <td valign="top">
                  <p>
                    如我們就閣下資料進行的處理乃根據閣下同意進行，閣下有權撤銷該等同意。此舉不應影響於撤銷之前根據閣下同意進行的資料處理之合法性。我們參照加插於「我們向閣下收集甚麼資料？我們又會如何使用該等資料」部分的表格，尤其是「我們處理閣下資料的法律基礎為何」一欄，辨識我們根據閣下同意所進行的資料處理。
                    <br />
                    如閣下欲對此提出反對並撤銷同意，請透過以下詳情聯絡我們。
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p>為處理閣下的要求，我們或會要求閣下提供身分證明。</p>

          <p>本私隱政策備有中文版本。如有歧義，概以英文版本為準。</p>

          <p>2020年6月24日</p>

          <br />
          <br />

          <h1 id="pics">個人資料收集聲明</h1>
          <p>
            <b>收集閣下的個人資料</b>
            <br />
            我們 (歐萊雅香港有限公司) (下稱「<b>我們</b>」或「<b>歐萊雅</b>」)
            旗下的[BIOTHERM] (「<b>品牌</b>」)
            將根據本《個人資料收集聲明》，收集及儲存閣下的個人資料 (「
            <b>閣下資料</b>」)。閣下必須提供以星號 (*)
            標示的個人資料，而提供並無以星號 (*)
            標示的個人資料純屬自願，惟閣下倘不提供相關資料，我們可能無法向閣下提供我們的產品及服務。
          </p>
          <p>
            <b>使用閣下個人資料的目的</b>
            <br />
            我們將把閣下資料用作以下目的 (需視乎情況)：
          </p>
          <ol>
            <li>
              就閣下於我們的網上商店或零售店舖 (包括我們的零售店舖 /
              百貨公司夥伴) 購買我們的產品及 /
              或服務以及閣下的網上購物帳戶，處理、管理交易和與閣下聯絡；
            </li>
            <li>
              建立和管理閣下的品牌會籍和會員獎賞 (包括查詢及累積或兌現會員積分)
              ，並就此與閣下聯絡；
            </li>
            <li>
              就閣下參與歐萊雅舉辦的任何競賽、抽獎、遊戲、比賽、活動或推廣，向閣下提供免費產品、試用裝或禮品；
            </li>
            <li>就閣下查詢我們的產品及 / 或服務事宜與閣下通訊；</li>
            <li>為促成上述任何目的而識別和確認身分；</li>
            <li>作內部研究、評估和數據分析；</li>
            <li>(視乎閣下有否給予任何書面同意) 作直銷用途；及</li>
            <li>任何其他直接相關目的。</li>
          </ol>
          <p>
            (統稱「<b>使用目的</b>」)。
            <br />
            我們或會在閣下給予書面同意的情況下，透過電話、短訊、電郵或美容顧問在社交媒體平台通訊軟件
            (例如Facebook訊息、Instagram訊息、WhatsApp訊息、微信訊息等)
            的互動對話，與閣下聯絡並通訊。
          </p>
          <p>
            <b>轉移閣下的個人資料</b>
            <br />
            我們或會因應使用目的，向以下各方轉移、分享或供其存取閣下資料：
          </p>
          <ol>
            <li>
              歐萊雅或其集團旗下任何成員公司或附屬公司
              (包括位於香港境內或境外的該等公司) (統稱「歐萊雅集團」)；
            </li>
            <li>
              向歐萊雅或歐萊雅集團任何成員提供付款、資訊科技、研究、客戶概況分析、數據分析、市場推廣、電話中心、行政服務及為支持歐萊雅或歐萊雅集團任何成員業務營運所提供的任何其他服務之第三方服務提供者或代理；
            </li>
            <li>
              與閣下就購買我們的產品及 /
              或服務付款相關的信貸資料服務機構，以及信用卡、扣帳卡及 /
              或簽帳卡公司及 / 或銀行；及
            </li>
            <li>社交媒體平台提供者 (包括位於中國及美國的提供者)。</li>
          </ol>
          <p>
            <b>使用閣下的個人資料作直銷用途</b>
            <br />
            倘獲閣下同意我們使用閣下的個人資料作直銷用途，我們會利用閣下的姓名、電郵地址、電話號碼、郵寄地址、WhatsApp賬號及ID、WeChat賬號及ID及Facebook賬號及ID，就 (視乎情況) 品牌於香港特別行政區及澳門特別行政區供應的美容、護膚、化妝、護髮、頭髮造型、香水、香氛、蠟燭、潔手及其他相關產品及服務，經閣下指示的通訊渠道 (例如電話、短訊、電郵、WhatsApp訊息、WeChat訊息及Facebook訊息等) 向閣下傳送推廣內容、資訊及最新信息。未經閣下同意，我們不得使用及 / 或轉移閣下的個人資料作直銷用途。
          </p>
          <p>
            <b>閣下的權利和我們的聯絡方法</b>
            <br />
            閣下有權要求存取及修改由我們持有而與閣下相關的資料。如欲存取或修改閣下的個人資料，請透過電郵至
            <a href="mailto:privacy.corphk@loreal.com">
              privacy.corphk@loreal.com
            </a>
            與我們的法律部聯絡。如欲取消訂閱我們的直銷通訊或有任何一般查詢，請透過
            <a href="mailto:biotherm.csc@loreal.com">biotherm.csc@loreal.com</a>
            與我們聯絡。
            <br />
            如欲獲取我們如何使用閣下的個人資料的進一步資訊，請參閱我們的《私隱政策》：
            <Link to={`${genderPath}/privacy`}>
              http://www.biotherm.com.hk{genderPath}/privacy
            </Link>
            .<br />
            本《聲明》原文以英文撰寫，有可能翻譯為中文。如譯本與英文版本有任何不符之處，概以英文版本為準。
          </p>
        </div>
      )}
    </div>
  );
};

export default PrivacyEn;
